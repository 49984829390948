exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ads-jsx": () => import("./../../../src/pages/ads.jsx" /* webpackChunkName: "component---src-pages-ads-jsx" */),
  "component---src-pages-ads-plugin-privacy-jsx": () => import("./../../../src/pages/ads-plugin-privacy.jsx" /* webpackChunkName: "component---src-pages-ads-plugin-privacy-jsx" */),
  "component---src-pages-ads-plugin-privacy-without-topic-jsx": () => import("./../../../src/pages/ads-plugin-privacy-without-topic.jsx" /* webpackChunkName: "component---src-pages-ads-plugin-privacy-without-topic-jsx" */),
  "component---src-pages-analytics-jsx": () => import("./../../../src/pages/analytics.jsx" /* webpackChunkName: "component---src-pages-analytics-jsx" */),
  "component---src-pages-analytics-privacy-jsx": () => import("./../../../src/pages/analytics-privacy.jsx" /* webpackChunkName: "component---src-pages-analytics-privacy-jsx" */),
  "component---src-pages-analytics-privacy-without-topic-jsx": () => import("./../../../src/pages/analytics-privacy-without-topic.jsx" /* webpackChunkName: "component---src-pages-analytics-privacy-without-topic-jsx" */),
  "component---src-pages-comingsoon-jsx": () => import("./../../../src/pages/comingsoon.jsx" /* webpackChunkName: "component---src-pages-comingsoon-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-monetize-jsx": () => import("./../../../src/pages/monetize.jsx" /* webpackChunkName: "component---src-pages-monetize-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

